@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
/**
  * SETTINGS
  * Global...............Globally-available variables and config.
  *
  * BASE
  * Body.................Body styles.
  * Headings.............H1–H6 styles.
  * Links................Link styles.
  * Tables...............Table styles.
  *
  * TRUMPS
  * Text.................Text helpers.
*/

/* ------------------------------------ *\
  #Global
\* ------------------------------------ */

:root {
  --di-background-color: #f7f7f7;
  --di-background-error-color: #f2dfdf;
  --di-background-success-color: #e4fae1;
  --di-primary-color: #245fb3;
  --di-primary-hover-color: #3e81ce;
  --di-table-body-bg-hover-color: #fafafa;
  --di-table-border-color: #ccc;
  --di-text-link-color: #03c;
  --di-text-disabled-color: #999;
  --di-text-error-color: #b72727;
  --di-text-info-color: #22428d;
  --di-text-primary-color: #333;
  --di-text-success-color: #1d721f;
  --di-page-header-height: 48px;
  --global-header-height: 45px;
  --global-sidebar-width: 200px;
}

/* ------------------------------------*\
  #Body
\*------------------------------------ */
html,
body,
#root {
  background-color: #f7f7f7;
  background-color: var(--di-background-color);
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.49px;
  margin: 0;
  padding: 0;
}

/* ------------------------------------*\
  #Headings
\*------------------------------------ */
h1 {
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0.77px;
  margin: 0;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.77px;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.7px;
  margin: 0;
}

/* ------------------------------------*\
  #Links
\*------------------------------------ */

a {
  color: #03c;
  color: var(--di-text-link-color);
  text-decoration: none;
}

/* ------------------------------------*\
  #Tables
\*------------------------------------ */
table {
  border-collapse: collapse;
}

/* ------------------------------------ *\
  #Text
\* ------------------------------------ */
p {
  margin: 0;
}

.base-textarea {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: #767676;
  font-size: 13px;
  padding: 5px;
  resize: none;
  transition: border-color 0.3s ease-out;
}

.base-textarea.background-yellow {
  background-color: #fffbd9;
}

.base-textarea.disabled {
  background-color: #fbfafa;
  pointer-events: none;
}

.base-textarea.has-err {
  border-color: #f00;
}

.base-textarea.type-number {
  text-align: right;
}

.base-textarea:hover {
  border-color: var(--di-primary-color);
}

.base-textarea.disabled:hover {
  border-color: #ccc;
}

.base-textarea.has-err:hover {
  border-color: #f00;
}

.base-textarea:focus-visible {
  outline: var(--di-primary-color);
}

.base-textarea.has-err:focus-visible {
  outline: #f00;
}

.error-message {
  color: var(--di-text-error-color);
}

.sentence-box-error-tooltip-container {
  position: relative;
}

.SquareLabel_base__1F-YE {
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  line-height: 20px;
  height: 20px;
  text-align: center;
}

.SquareLabel_green__1IO0G {
  border-color: #1d721f;
  color: #1d721f;
}

.SquareLabel_red__1PQbl {
  border-color: #b72727;
  color: #b72727;
}

.SquareLabel_yellow__2rCAa {
  border-color: #db7521;
  color: #db7521;
}

.SquareLabel_grey__2DY_d {
  border-color: #505050;
  color: #505050;
}

.base-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: #333;
  font-size: 12px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  letter-spacing: 0.46px;
  opacity: 0.9;
  padding: 10px 10px 5px;
  position: absolute;
  text-align: left;
  width: 167px;
  z-index: 2;
}

.label {
  margin-right: 5px;
  white-space: nowrap;
}

.row {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 5px;
}

.dots {
  overflow: hidden;
}

.dots::before {
  content: "························";
  font-size: 10px;
  letter-spacing: -1px;
}

.value {
  margin-left: 5px;
}

.week {
  font-weight: bold;
  margin-bottom: 5px;
}

.base-label {
  display: block;
  margin-bottom: 5px;
  margin-left: 5px;
}

.base-sub-label {
  color: var(--di-text-disabled-color);
}

.calendar-icon {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}

.base-input {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  font-size: 14px;
  pointer-events: auto;
  transition: border-color 0.3s ease-out;
}

.base-input-container.type-date {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

input.base-input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  z-index: 100;
}

.base-input.background-yellow {
  background-color: #fffbd9;
}

.base-input.disabled {
  background-color: #fbfafa;
  pointer-events: none;
}

.base-input.has-err {
  border-color: #f00;
}

.base-input.type-number {
  text-align: right;
}

.base-input::-webkit-calendar-picker-indicator {
  margin-right: -3px;
  margin-left: 0;
}

.base-input::-webkit-outer-spin-button,
.base-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

.base-input:hover {
  border-color: var(--di-primary-color);
}

.base-input.disabled:hover {
  border-color: #ccc;
}

.base-input.has-err:hover {
  border-color: #f00;
}

.base-input:focus-visible {
  outline: var(--di-primary-color);
}

.base-input.has-err:focus-visible {
  outline: #f00;
}

.error-message {
  color: var(--di-text-error-color);
}

.base-inventory-plan-table {
  border-bottom: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  table-layout: fixed;
  white-space: nowrap;
}

.dropdown-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 25px;
  position: relative;
}

.inventory-plan-table-container {
  border-left: solid 1px var(--di-table-border-color);
  overflow-x: scroll;
}

.inventory-plan-table-data {
  background-color: #fff;
  color: var(--di-text-primary-color);
  border-bottom: 1px solid var(--di-table-border-color);
  border-left: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  height: 25px;
  padding-bottom: 0;
  padding-right: 8px;
  padding-top: 0;
  text-align: right;
}

.inventory-plan-table-data.label {
  font-size: 13px;
}

.inventory-plan-table-data.error {
  color: var(--di-text-error-color);
}

.inventory-plan-table-data.bold-bottom-border {
  border-bottom-color: #707070;
}

.inventory-plan-table-data.red {
  background-color: #f8d9d9;
}

.inventory-plan-table-data.highlight {
  background-color: #fafafa;
}

.inventory-plan-table-data:first-child {
  background-color: #f1f1f1;
  border-left: none;
  left: 0;
  padding-left: 11px;
  position: -webkit-sticky;
  position: sticky;
  text-align: left;
  z-index: 2;
}

.inventory-plan-table-data:first-child::before {
  border-right: solid 1px #707070;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.inventory-plan-table-header {
  background-color: #f1f1f1;
  border-bottom-width: 1px solid var(--di-table-border-color);
  border-left: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  font-weight: normal;
  height: 25px;
  padding: 0 8;
  text-align: left;
}

.inventory-plan-table-header.tall-header {
  height: 29px;
}

.inventory-plan-table-header.align-right {
  text-align: right;
}

.inventory-plan-table-header.bold-bottom-border {
  border-bottom-color: #707070;
}

.inventory-plan-table-header:first-of-type {
  border-left: none;
  left: 0;
  padding-left: 11px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.inventory-plan-table-header:first-child::before {
  border-right: solid 1px #707070;
  content: "";
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.shipment-reason-label {
  font-size: 9px;
}

.standard-of-inventory-tooltip-container {
  position: relative;
}

.text-box-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 25px;
  position: relative;
}

.demand-aggregation-between {
  color: #767676;
  margin: 0 10px;
}

.demand-aggregation-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.demand-aggregation-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 143px;
}

.sku-list-table-container {
  overflow-x: scroll;
}

.demand-aggregation-between {
  color: #767676;
  margin: 0 5px;
  font-size: 8px;
}

.demand-aggregation-label {
  color: #767676;
  font-weight: bold;
}

.demand-aggregation-wrapper {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.product-info-space {
  margin-right: 16px;
}

