.base-textarea {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: #767676;
  font-size: 13px;
  padding: 5px;
  resize: none;
  transition: border-color 0.3s ease-out;
}

.base-textarea.background-yellow {
  background-color: #fffbd9;
}

.base-textarea.disabled {
  background-color: #fbfafa;
  pointer-events: none;
}

.base-textarea.has-err {
  border-color: #f00;
}

.base-textarea.type-number {
  text-align: right;
}

.base-textarea:hover {
  border-color: var(--di-primary-color);
}

.base-textarea.disabled:hover {
  border-color: #ccc;
}

.base-textarea.has-err:hover {
  border-color: #f00;
}

.base-textarea:focus-visible {
  outline: var(--di-primary-color);
}

.base-textarea.has-err:focus-visible {
  outline: #f00;
}

.error-message {
  color: var(--di-text-error-color);
}

.sentence-box-error-tooltip-container {
  position: relative;
}
