.base-label {
  display: block;
  margin-bottom: 5px;
  margin-left: 5px;
}

.base-sub-label {
  color: var(--di-text-disabled-color);
}

.calendar-icon {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.base-input {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  font-size: 14px;
  pointer-events: auto;
  transition: border-color 0.3s ease-out;
}

.base-input-container.type-date {
  position: relative;
  width: fit-content;
}

input.base-input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  z-index: 100;
}

.base-input.background-yellow {
  background-color: #fffbd9;
}

.base-input.disabled {
  background-color: #fbfafa;
  pointer-events: none;
}

.base-input.has-err {
  border-color: #f00;
}

.base-input.type-number {
  text-align: right;
}

.base-input::-webkit-calendar-picker-indicator {
  margin-right: -3px;
  margin-left: 0;
}

.base-input::-webkit-outer-spin-button,
.base-input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.base-input:hover {
  border-color: var(--di-primary-color);
}

.base-input.disabled:hover {
  border-color: #ccc;
}

.base-input.has-err:hover {
  border-color: #f00;
}

.base-input:focus-visible {
  outline: var(--di-primary-color);
}

.base-input.has-err:focus-visible {
  outline: #f00;
}

.error-message {
  color: var(--di-text-error-color);
}
