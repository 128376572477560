.demand-aggregation-between {
  color: #767676;
  margin: 0 5px;
  font-size: 8px;
}

.demand-aggregation-label {
  color: #767676;
  font-weight: bold;
}

.demand-aggregation-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}
