/**
  * SETTINGS
  * Global...............Globally-available variables and config.
  *
  * BASE
  * Body.................Body styles.
  * Headings.............H1–H6 styles.
  * Links................Link styles.
  * Tables...............Table styles.
  *
  * TRUMPS
  * Text.................Text helpers.
*/

/* ------------------------------------ *\
  #Global
\* ------------------------------------ */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

:root {
  --di-background-color: #f7f7f7;
  --di-background-error-color: #f2dfdf;
  --di-background-success-color: #e4fae1;
  --di-primary-color: #245fb3;
  --di-primary-hover-color: #3e81ce;
  --di-table-body-bg-hover-color: #fafafa;
  --di-table-border-color: #ccc;
  --di-text-link-color: #03c;
  --di-text-disabled-color: #999;
  --di-text-error-color: #b72727;
  --di-text-info-color: #22428d;
  --di-text-primary-color: #333;
  --di-text-success-color: #1d721f;
  --di-page-header-height: 48px;
  --global-header-height: 45px;
  --global-sidebar-width: 200px;
}

/* ------------------------------------*\
  #Body
\*------------------------------------ */
html,
body,
#root {
  background-color: var(--di-background-color);
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.49px;
  margin: 0;
  padding: 0;
}

/* ------------------------------------*\
  #Headings
\*------------------------------------ */
h1 {
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0.77px;
  margin: 0;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.77px;
  margin: 0;
}

h3 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.7px;
  margin: 0;
}

/* ------------------------------------*\
  #Links
\*------------------------------------ */

a {
  color: var(--di-text-link-color);
  text-decoration: none;
}

/* ------------------------------------*\
  #Tables
\*------------------------------------ */
table {
  border-collapse: collapse;
}

/* ------------------------------------ *\
  #Text
\* ------------------------------------ */
p {
  margin: 0;
}
