.base-inventory-plan-table {
  border-bottom: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  table-layout: fixed;
  white-space: nowrap;
}

.dropdown-container {
  align-items: center;
  display: flex;
  height: 25px;
  position: relative;
}

.inventory-plan-table-container {
  border-left: solid 1px var(--di-table-border-color);
  overflow-x: scroll;
}

.inventory-plan-table-data {
  background-color: #fff;
  color: var(--di-text-primary-color);
  border-bottom: 1px solid var(--di-table-border-color);
  border-left: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  height: 25px;
  padding-bottom: 0;
  padding-right: 8px;
  padding-top: 0;
  text-align: right;
}

.inventory-plan-table-data.label {
  font-size: 13px;
}

.inventory-plan-table-data.error {
  color: var(--di-text-error-color);
}

.inventory-plan-table-data.bold-bottom-border {
  border-bottom-color: #707070;
}

.inventory-plan-table-data.red {
  background-color: #f8d9d9;
}

.inventory-plan-table-data.highlight {
  background-color: #fafafa;
}

.inventory-plan-table-data:first-child {
  background-color: #f1f1f1;
  border-left: none;
  left: 0;
  padding-left: 11px;
  position: sticky;
  text-align: left;
  z-index: 2;
}

.inventory-plan-table-data:first-child::before {
  border-right: solid 1px #707070;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.inventory-plan-table-header {
  background-color: #f1f1f1;
  border-bottom-width: 1px solid var(--di-table-border-color);
  border-left: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  font-weight: normal;
  height: 25px;
  padding: 0 8;
  text-align: left;
}

.inventory-plan-table-header.tall-header {
  height: 29px;
}

.inventory-plan-table-header.align-right {
  text-align: right;
}

.inventory-plan-table-header.bold-bottom-border {
  border-bottom-color: #707070;
}

.inventory-plan-table-header:first-of-type {
  border-left: none;
  left: 0;
  padding-left: 11px;
  position: sticky;
  z-index: 2;
}

.inventory-plan-table-header:first-child::before {
  border-right: solid 1px #707070;
  content: "";
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.shipment-reason-label {
  font-size: 9px;
}

.standard-of-inventory-tooltip-container {
  position: relative;
}

.text-box-container {
  align-items: center;
  display: flex;
  height: 25px;
  position: relative;
}
