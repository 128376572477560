.base {
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  line-height: 20px;
  height: 20px;
  text-align: center;
}

.green {
  border-color: #1d721f;
  color: #1d721f;
}

.red {
  border-color: #b72727;
  color: #b72727;
}

.yellow {
  border-color: #db7521;
  color: #db7521;
}

.grey {
  border-color: #505050;
  color: #505050;
}
