.base-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: #333;
  font-size: 12px;
  height: fit-content;
  letter-spacing: 0.46px;
  opacity: 0.9;
  padding: 10px 10px 5px;
  position: absolute;
  text-align: left;
  width: 167px;
  z-index: 2;
}

.label {
  margin-right: 5px;
  white-space: nowrap;
}

.row {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.dots {
  overflow: hidden;
}

.dots::before {
  content: "························";
  font-size: 10px;
  letter-spacing: -1px;
}

.value {
  margin-left: 5px;
}

.week {
  font-weight: bold;
  margin-bottom: 5px;
}
